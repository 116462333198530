import {Component, Input, OnInit} from '@angular/core';
import {ShopService} from '../../../dao/impl/shop/shop.service';
import {GroupService} from '../../../dao/impl/group/group.service';
import {ProductService} from '../../../dao/impl/product/product.service';
import {ShopResponse} from '../../../model/shop/ShopResponse';
import {ToastrService} from 'ngx-toastr';
import {Event} from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  currentIndex = -1;

  groupItems = [];

  shopSettings: ShopResponse;

  @Input('shopSettings')
  set setShopSetting(shopSettings: ShopResponse) {
    if (shopSettings) {
      this.shopSettings = shopSettings;
      this.getGroups();
    }

  }

  constructor(private shopService: ShopService,
              private groupService: GroupService,
              private productService: ProductService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  // получение списка групп товаров у магазина
  getGroups() {
    this.groupService.getAllGroup(this.shopSettings.id).subscribe(data => {
        this.groupItems = data;
      },
      error => {
        this.toastr.error(error.message, 'Error');
      });
  }

  onTabClick(event: Event) {
    this.currentIndex = -1;
  }

}


