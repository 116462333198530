export class ShoppingCartModificators {

  id: number;
  name: string;
  price: number;
  count: number;

  constructor(id: number, name: string, price: number, count: number) {

    this.id = id;
    this.name = name;
    this.price = price ? price : 0;
    this.count = count ? count : 0;

  }
}
