import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {GroupDAO} from '../../interface/group/GroupDAO';
import {Observable} from 'rxjs';
import {GroupResponse} from '../../../model/group/GroupResponse';
import {ShopResponse} from '../../../model/shop/ShopResponse';

@Injectable({
  providedIn: 'root'
})
export class GroupService implements GroupDAO{

  url = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  getAllGroup(shopId: number): Observable<GroupResponse[]> {
    return this.http.get<ShopResponse[]>('/api/user/productgroup?shopId=' + shopId);
  }

}
