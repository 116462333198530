import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ShoppingCartProduct, ShoppingCartProductWrapper} from '../model/shoppingcart/ShoppingCartProduct';
import {Md5} from 'ts-md5/dist/md5';
import {environment} from '../../environments/environment.prod';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  emitChangeSource = new Subject<ShoppingCartProductWrapper[]>();

  changeEmittedShoppingCart$ = this.emitChangeSource.asObservable();

  shopingCartProductsWrapper: ShoppingCartProductWrapper[] = [];

  shopPrefix = environment.shopPrefix;

  countShopCart: number;

  constructor(private cookieService: CookieService) {
    const coockeShopCart = this.cookieService.get(this.shopPrefix + '_shop_cart');
    if (coockeShopCart) {
      this.shopingCartProductsWrapper = JSON.parse(coockeShopCart);
      this.emitChangeShoppingCart(this.shopingCartProductsWrapper);
    }
  }


  // отправка сообщения
  emitChangeShoppingCart(change: ShoppingCartProductWrapper[]) {
    this.calculateCountShopCart(change);
    this.emitChangeSource.next(change);
  }

  addShoppingCartProduct(shoppingCartProduct: ShoppingCartProduct) {

    if (this.shopingCartProductsWrapper.length === 0) {
      this.shopingCartProductsWrapper.push(new ShoppingCartProductWrapper(1, shoppingCartProduct));
    } else {
      let newProduct = true;

      const md5NewProduct = Md5.hashStr(JSON.stringify(shoppingCartProduct));

      this.shopingCartProductsWrapper.forEach(item => {

        const md5ItemProduct = Md5.hashStr(JSON.stringify(item.shoppingCartProduct));

        if (md5NewProduct === md5ItemProduct) {
          item.count++;
          newProduct = false;
        }

      });

      if (newProduct) {
        this.shopingCartProductsWrapper.push(new ShoppingCartProductWrapper(1, shoppingCartProduct));
      }
    }

    this.shopingCartProductsWrapper.forEach(spw => {

      spw.shoppingCartProduct.shoppingCartModificators.forEach(m => {
      });
    });


    this.cookieService.set(this.shopPrefix + '_shop_cart', JSON.stringify(this.shopingCartProductsWrapper));
    this.emitChangeShoppingCart(this.shopingCartProductsWrapper);
  }

  calculateCountShopCart(shopingCartProductsWrapper: ShoppingCartProductWrapper[]) {
    this.countShopCart = 0;
    shopingCartProductsWrapper.forEach(item => {
      this.countShopCart = this.countShopCart + item.count;
    });
  }

  deleteShoppingCartProduct(shoppingCartProduct: ShoppingCartProduct) {
    const md5NewProduct = Md5.hashStr(JSON.stringify(shoppingCartProduct));
    this.shopingCartProductsWrapper.forEach(spw => {
      const md5ItemProduct = Md5.hashStr(JSON.stringify(spw.shoppingCartProduct));
      if (md5NewProduct === md5ItemProduct) {
        if (spw.count > 1) {
          spw.count = spw.count - 1;
        } else if (spw.count === 1) {
          spw.count = spw.count - 1;
          this.shopingCartProductsWrapper = this.shopingCartProductsWrapper.filter(obj => obj !== spw);
        }
      }
    });
    this.cookieService.set(this.shopPrefix + '_shop_cart', JSON.stringify(this.shopingCartProductsWrapper));
    this.emitChangeShoppingCart(this.shopingCartProductsWrapper);
  }

  deleteAllSoppingCartProduct(shoppingCartProduct: ShoppingCartProduct) {
    const md5NewProduct = Md5.hashStr(JSON.stringify(shoppingCartProduct));
    this.shopingCartProductsWrapper.forEach(spw => {
      const md5ItemProduct = Md5.hashStr(JSON.stringify(spw.shoppingCartProduct));
      if (md5NewProduct === md5ItemProduct) {
        spw.count = 0;
        this.shopingCartProductsWrapper = this.shopingCartProductsWrapper.filter(obj => obj !== spw);
      }
    });
    this.cookieService.set(this.shopPrefix + '_shop_cart', JSON.stringify(this.shopingCartProductsWrapper));
    this.emitChangeShoppingCart(this.shopingCartProductsWrapper);
  }

  deleteAllSoppingCart() {
    this.shopingCartProductsWrapper = [];
    this.cookieService.set(this.shopPrefix + '_shop_cart', JSON.stringify(this.shopingCartProductsWrapper));
    this.emitChangeShoppingCart(this.shopingCartProductsWrapper);
  }

  getSumShopCart(): number {
    let sum = 0;
    this.shopingCartProductsWrapper.forEach(scpw => {
      sum = sum + (scpw.count * scpw.shoppingCartProduct.price);
    });
    return Number(sum.toFixed(2));
  }

}
