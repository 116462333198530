import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ShopResponse} from "../../model/shop/ShopResponse";
import {AreaDeliveryResponse} from "../../model/areadelivey/AreaDeliveryResponse";
import {ShoppingCartService} from "../../service/shopping-cart.service";
import {ShoppingCartProduct, ShoppingCartProductWrapper} from "../../model/shoppingcart/ShoppingCartProduct";
import {environment} from "../../../environments/environment.prod";
import {SendSmsResponse} from "../../model/auth/SendSmsResponse";
import {AuthService} from "../../dao/impl/auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {ShopBranchResponse} from "../../model/shop/ShopBranchResponse";
import {OrderService} from "../../dao/impl/order/order.service";
import {OrderResponse} from "../../model/order/OrderResponse";
import {OrderRequest} from "../../model/order/OrderRequest";
import {AnyDialogComponent} from "../any-dialog/any-dialog.component";

@Component({
  selector: 'app-shop-cart-dialog',
  templateUrl: './shop-cart-dialog.component.html',
  styleUrls: ['./shop-cart-dialog.component.css']
})
export class ShopCartDialogComponent implements OnInit {

  shopSetting: ShopResponse;
  areasDelivery: AreaDeliveryResponse[];
  nameNow: string = '';
  phoneNow: string = '';
  streeNow: string = '';
  houseNow: string = '';
  apartmentNow: string = '';
  entranceNow: string = '';
  floorNow: string = '';
  commentNow: string = '';

  phoneForSms: string = '';
  smsCode: string = '';

  shopingCartProductsWrapper: ShoppingCartProductWrapper[] = [];
  shopBranches: ShopBranchResponse[] = [];

  url = environment.apiBaseUrl;

  currentUser: SendSmsResponse;

  isAuth: boolean;

  currentState: number;
  smsGetState = 1;
  smsSendState = 2;
  isAuthState = 3;

  currentDelivery: string;

  currentAreaDelivery: AreaDeliveryResponse;
  currentShopBranch: ShopBranchResponse;
  currentOrderResponse: OrderResponse;

  currentUUID: string = '0';

  paymentIsBlock = false;

  minPaymentOrder = 0;

  terminal = environment.terminal;

  currentPaymentType: string;
  paymentTypeForOrder: string | null;

  textButtonOrder: string;

  constructor(private dialogRef: MatDialogRef<ShopCartDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: [ShopResponse, AreaDeliveryResponse[], ShopBranchResponse[]],
              private shoppingCartService: ShoppingCartService,
              private dialog: MatDialog,
              private authService: AuthService,
              private toastr: ToastrService,
              private orderService: OrderService
  ) {
    this.currentUser = this.authService.currentUserValue;
    this.isAuth = !!this.currentUser;
    this.currentPaymentType = 'onlinePayment';
    this.textButtonOrder = 'Оплатить';
    this.paymentTypeForOrder = null;
  }

  ngOnInit(): void {
    this.loadScript('https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"');
    this.shopSetting = this.data[0];
    this.areasDelivery = this.data[1];
    this.shopBranches = this.data[2];
    this.shopingCartProductsWrapper = this.shoppingCartService.shopingCartProductsWrapper;
    this.currentState = this.smsGetState;

    this.checkTime();
  }

  addProductShopCart(shoppingCartProduct: ShoppingCartProduct) {
    this.shoppingCartService.addShoppingCartProduct(shoppingCartProduct)
  }

  deleteProductShopCart(shoppingCartProduct: ShoppingCartProduct) {
    this.shoppingCartService.deleteShoppingCartProduct(shoppingCartProduct);
  }

  deleteAllProductShopCart(shoppingCartProduct: ShoppingCartProduct) {
    this.shoppingCartService.deleteAllSoppingCartProduct(shoppingCartProduct);
  }

  getPrice(price: number) {
    return Number((price).toFixed(2));
  }

  getModificatorsString(shoppingCartProduct: ShoppingCartProduct): string {
    let str = '';
    shoppingCartProduct.shoppingCartModificators.forEach(modif => {
      if (modif.count !== 0) {
        str = str + modif.name + '*' + modif.count + ', '
      }
    });
    str = str.substring(0, str.length - 2);
    return str;
  }

  getUrlPhoto(path: string): string {
    return this.url + path;
  }

  close() {
    this.dialogRef.close();
  }

  getSumShopCart(): number {
    return this.shoppingCartService.getSumShopCart();
  }

  sendSms() {
    let phone: string = '7' + this.phoneForSms;
    if (this.currentState === this.smsGetState) {
      this.authService.getSms4(phone).subscribe(data => {
          if (data) {
            if (data.status === 'auth' || data.status === 'reg') {
              this.currentState = this.smsSendState;
            }
          }
        },
        error => {
          this.toastr.error(error.message, 'Error');
        });
    } else if (this.currentState === this.smsSendState) {
      this.authService.sendSms(phone, this.smsCode).subscribe(
        data => {
          if (data) {
            this.currentState = this.isAuthState;
            this.isAuth = true;
          }
        },
        error => {
          this.toastr.error('Неверный код СМС', 'Ошибка');
        }
      )
    }
  }

  getTitleButtonSms() {
    if (this.currentState === this.smsGetState) {
      return 'Запросить СМС код';
    } else if (this.currentState === this.smsSendState) {
      return 'Подтвердить'
    }
  }

  createOrderAndPayment(e: any) {
    if (this.checkTime()) {
      let currentDeliveryId = this.currentAreaDelivery == undefined ? null : this.currentAreaDelivery.id;
      let currentShopBranchId = this.currentShopBranch == undefined ? null : this.currentShopBranch.id;
      let orderRequest: OrderRequest = new OrderRequest(this.shopingCartProductsWrapper,
        currentShopBranchId, 0, this.currentDelivery,
        this.getAdr(), currentDeliveryId, this.commentNow + ' -- Заказ из WEB версии',  this.paymentTypeForOrder);
      this.orderService.createOrder(orderRequest).subscribe(data => {
        if (data) {
          this.currentOrderResponse = data;
          this.currentUUID = this.currentOrderResponse.uuid;

          this.paymentIsBlock = true;
          setTimeout(() => {
            if (this.paymentTypeForOrder == null) {
              e.click();
            } else {
              this.paymentCahOrTerminal();
            }
          }, 100);

        }
      }, error1 => {
        this.toastr.error(error1.message, 'Error');
      });
    } else {

      const dialogRef = this.dialog.open(AnyDialogComponent, {
        maxWidth: '500px',
        data: ['Внимание', 'В данный момент магазин закрыт. Время приёма заказов рестораном - ежедневно с' +
        this.shopSetting.orderBeginTime + ' до ' + this.shopSetting.orderEndTime],
      });
    }


  }

  checkTime(): boolean {
    let dateTime = new Date();
    let orderEndTime = this.shopSetting.orderEndTime;
    let orderBeginTime = this.shopSetting.orderBeginTime;

    let orderEndTimeArr = orderEndTime.split(':');
    let orderBeginTimeArr = orderBeginTime.split(':');

    let currentHour = dateTime.getHours();
    let currentMinute = dateTime.getMinutes();

    let orderEndTimeHour = Number(orderEndTimeArr[0]);
    let orderEndTimeMinute = Number(orderEndTimeArr[1]);

    let orderBeginTimeHour = Number(orderBeginTimeArr[0]);
    let orderBeginTimeMinute = Number(orderBeginTimeArr[1]);

    let curMinute: number = (currentHour * 60) + currentMinute;
    let orderBeginMinute: number = (orderBeginTimeHour * 60) + orderBeginTimeMinute;
    let orderEndMinute: number = (orderEndTimeHour * 60) + orderEndTimeMinute;


    if (curMinute > orderBeginMinute) {
      return curMinute < orderEndMinute;
    } else {
      return false;
    }


  }

   paymentCahOrTerminal() {
    this.shoppingCartService.deleteAllSoppingCart();
    this.dialogRef.close();
    const dialogRef = this.dialog.open(AnyDialogComponent, {
      maxWidth: '500px',
      data: ['Отлично', 'Ваш заказ сформирован, скоро с Вами свяжется оператор'],
    });
  }


  onSelectDelivery() {
    if (this.areasDelivery.length == 1) {
      this.currentAreaDelivery = this.areasDelivery[0];
      this.minPaymentOrder = this.currentAreaDelivery.minimumOrderAmount;
    }

    if (this.shopBranches.length == 1) {
      this.currentShopBranch = this.shopBranches[0];
    }
  }

  onSelectedDeliveryArea(item: AreaDeliveryResponse) {
    this.currentAreaDelivery = item;
    this.minPaymentOrder = this.currentAreaDelivery.minimumOrderAmount;
  }

  getAdr(): string {
    return this.streeNow + ', ' + this.houseNow + ', подъезд ' + this.entranceNow + ',  этаж ' + this.floorNow + ', квартира ' + this.apartmentNow
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  validationPaymentButton(): boolean {

    if (this.currentDelivery == null) {
      return true;
    }

    // доставка
    if (this.currentDelivery == 'DELIVERY') {
      if (this.currentAreaDelivery == undefined) {
        return true;
      }
      if (this.phoneNow == undefined) {
        return true;
      }
      if (this.phoneNow.length !== 10) {
        return true;
      }
      if (this.streeNow == undefined) {
        return true;
      }
      if (this.streeNow.length < 2) {
        return true;
      }
      if (this.getSumShopCart() < this.minPaymentOrder) {
        return true;
      }
    }
    // самовывоз
    else if (this.currentDelivery == 'PICKUP') {
      if (this.currentShopBranch == undefined) {
        return true;
      }
      if (this.phoneNow == undefined) {
        return true;
      }
      if (this.phoneNow.length !== 10) {
        return true;
      }
    }
  }

  backMenu() {
    this.dialogRef.close();
  }

  onValChangePayment(typePayment: string) {
    this.currentPaymentType = typePayment;
    switch(this.currentPaymentType ) {
      case 'onlinePayment': {
        this.paymentTypeForOrder = null;
        this.textButtonOrder = 'Оплатить';
        break;
      }
      case 'cashPayment': {
        this.paymentTypeForOrder = 'CASH';
        this.textButtonOrder = 'Заказать';
        break;
      }
      case 'terminalPayment': {
        this.paymentTypeForOrder = 'TERMINAL';
        this.textButtonOrder = 'Заказать';
        break;
      }
    }
  }


}
