import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {ShopDAO} from '../../interface/shop/ShopDAO';
import {Observable} from 'rxjs';
import {ShopResponse} from '../../../model/shop/ShopResponse';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShopService implements ShopDAO{

  url = environment.apiBaseUrl;
  shopPrefix = environment.shopPrefix;

  constructor(private http: HttpClient) { }

  getShop(): Observable<ShopResponse> {
    return this.http.get<ShopResponse>('/api/user/shop/' + this.shopPrefix);
  }


}
