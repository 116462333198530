import {Component, OnInit} from '@angular/core';
import {AnyDialogComponent} from "../../dialog/any-dialog/any-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment.prod";
import {TermsService} from "../../dao/impl/terms/terms.service";
import {AnyDialogHtmlComponent} from "../../dialog/any-dialog-html/any-dialog-html.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  adressFooter = environment.adressFooter;
  instagramFooter = environment.instagramFooter;
  phoneFooter = environment.phoneFooter;
  callPhone = environment.callPhone;
  mailFooter = environment.mailFooter;
  infoCompaniFooter = environment.infoCompaniFooter;

  deliveryStr: string = '';
  serviceStr: string = '';

  constructor(private dialog: MatDialog, private termsService: TermsService) {
  }

  ngOnInit(): void {
    this.getDelivery();
    this.getService();
  }

  getDelivery() {
    this.termsService.getDelivery().subscribe(data => {
      this.deliveryStr = data.terms;
    });
  }

  getService() {
    this.termsService.getService().subscribe(data => {
      this.serviceStr = data.terms;
    });
  }

  termsOfDelivery() {
    const dialogRef = this.dialog.open(AnyDialogComponent, {
      maxWidth: '500px',
      data: ['Условия доставки', this.deliveryStr],
    });
  }

  termsOfService() {
    const dialogRef = this.dialog.open(AnyDialogComponent, {
      maxWidth: '500px',
      data: ['Условия обслуживания', this.serviceStr],
    });

  }

  openPrize() {
    const dialogRef = this.dialog.open(AnyDialogHtmlComponent, {
      maxWidth: '700px',
      data: ['Условия розыгрыша', '<!-- #######  YAY, I AM THE SOURCE EDITOR! #########-->\n' +
      '<p class="p1" style="text-align: justify;">- Розыгрыш проводится ООО "ЕРА" "Трехэтажная пицца".</p>\n' +
      '<p class="p1" style="text-align: justify;">- Розыгрыш проводится с 12:00 06.02.2021 по 23:59 13.02.2021</p>\n' +
      '<p class="p1" style="text-align: justify;">- В розыгрыше имеют право принимать лица достигшие 18 лет.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
      '<p class="p1" style="text-align: justify;">- Победители определятся путём выбора рандомайзером из числа участвующих в прямом эфире (наш инстаграм). Всего будет выбрано 10 аккаунтов.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
      '<p class="p1" style="text-align: justify;">- Каждый из победителей может сделать заказ на одну бесплатную трехэтажную пиццу.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
      '<p class="p1" style="text-align: justify;">- Результаты будут объявлены 14.02.2021 с 10:00 до 11:00 по Новосибирскому времени<span class="Apple-converted-space">&nbsp; </span>на странице (наш инстаграмм)</p>\n' +
      '<p class="p1" style="text-align: justify;">- Воспользоваться возможностью заказа и доставки пиццы можно с 14.02.2021 по 21.02.2021. Для этого необходимо связаться с администратором ресторана по номеру телефона +79137550704, верифицировать Ваш аккаунт и сделать заказ.</p>\n' +
      '<p class="p1" style="text-align: justify;">- Заказы на доставку принимаются минимум за 4 часа до желаемого времени доставки.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
      '<p class="p1" style="text-align: justify;">- Доставка выигранных заказов производится по территории г. Новосибирск, г. Бердск, р.п. Кольцово с 10:00 до 20:00 с 14.02.2021 по 21.02.2021г.</p>'],
    });
  }

}
