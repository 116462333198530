import { Injectable } from '@angular/core';
import {ShopBranchDAO} from "../../interface/shop/ShopBranchDAO";
import {Observable} from "rxjs";
import {ShopBranchResponse} from "../../../model/shop/ShopBranchResponse";
import {ShopResponse} from "../../../model/shop/ShopResponse";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ShopBranchesService implements ShopBranchDAO{

  constructor(private http: HttpClient) { }

  getAllBranches(shopId: number): Observable<ShopBranchResponse[]> {
    return this.http.get<ShopBranchResponse[]>('/api/user/branch?shopId=' + shopId);
  }
}
