<footer id="footer" class="footer-area">

  <div class="footer-shape shape-1"></div>
  <div class="footer-shape shape-2"></div>
  <div class="footer-shape shape-3"></div>
  <div class="footer-shape shape-4"></div>
  <div class="footer-shape shape-5"></div>
  <div class="footer-shape shape-6"></div>
  <div class="footer-shape shape-7"></div>
  <div class="footer-shape shape-8">
    <img class="svg" src="../assets/images/footer-shape.svg" alt="Shape">
  </div>

  <div class="footer-widget pt-30 pb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about mt-50 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.2s">
            <a class="logo" href="/">
              <img src="../assets/images/logo.png" alt="Logo">
            </a>
            <!--<p class="text">Тут краткий текст</p>-->
            <!--<ul class="social">-->
            <!--<li><a href="#"><i class="lni lni-facebook"></i></a></li>-->
            <!--<li><a href="#"><i class="lni lni-twitter"></i></a></li>-->
            <!--<li><a href="#"><i class="lni lni-instagram"></i></a></li>-->
            <!--<li><a href="#"><i class="lni lni-linkedin"></i></a></li>-->
            <!--</ul>-->
          </div> <!-- footer about -->
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="footer-link d-flex flex-wrap">
            <div class="footer-link-wrapper mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.4s">
              <div class="footer-title">
                <h4 class="title">Навигация</h4>
              </div>
              <ul class="link">
                <li><a class="" href="#home">Главная</a></li>
                <li><a class="" href="#why">Меню</a></li>
                <li><a class="" href="#download">Приложение</a></li>
              </ul>
            </div> <!-- footer link wrapper -->

            <div class="footer-link-wrapper mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.6s">
              <div class="footer-title">
                <h4 class="title">Поддержка</h4>
              </div>
              <ul class="link">
                <li (click)="termsOfDelivery()" style="cursor: pointer;"><a>Условия доставки</a></li>
                <li (click)="termsOfService()" style="cursor: pointer;"><a>Условия обслуживания</a></li>
                <li (click)="openPrize()" style="cursor: pointer;"><a>Условия розыгрыша</a></li>
              </ul>
            </div> <!-- footer link wrapper -->
          </div> <!-- footer link -->
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-contact mt-45 wow fadeIn" data-wow-duration="1.3s" data-wow-delay="0.8s">
            <div class="footer-title">
              <h4 class="title">Контакты</h4>
            </div>
            <ul class="contact-list">
              <li>
                <div class="contact-info d-flex">
                  <div class="info-content media-body">
                    <p class="text"><i class="lni lni-phone"></i> <a [attr.href]="'tel:'+ callPhone"> {{phoneFooter}}</a>
                    </p>
                  </div>
                </div> <!-- contact info -->
              </li>
              <li>
                <div class="contact-info d-flex">
                  <div class="info-content media-body">
                    <p class="text"><a [attr.href]="'mailto:'+ mailFooter"><i class="lni lni-envelope"></i>
                      {{mailFooter}}</a></p>
                  </div>
                </div> <!-- contact info -->
              </li>
              <li>
                <div class="contact-info d-flex">
                  <div class="info-content media-body">
                    <p class="text"><a target="_blank" [attr.href]="'https://' + instagramFooter"><i class="lni lni-instagram"></i>
                      {{instagramFooter}}</a></p>
                  </div>
                </div> <!-- contact info -->
              </li>
              <li>
                <div class="contact-info d-flex">
                  <div class="info-content media-body">
                    <p class="text"><i class="lni lni-map"></i> {{adressFooter}}</p>
                  </div>
                </div> <!-- contact info -->
              </li>
            </ul> <!-- contact list -->
          </div> <!-- footer contact -->
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
  </div> <!-- footer widget -->

  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="copyright d-sm-flex justify-content-between">
            <div class="copyright-text text-center">
              <p class="text">Разработано <a rel="nofollow" target="_blank" href="https://food-assist.ru/">food-assist.ru</a></p>
            </div> <!-- copyright text -->

            <div class="copyright-privacy text-center">
              <p>{{infoCompaniFooter}}</p>
            </div> <!-- copyright privacy -->

          </div> <!-- copyright -->
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
  </div> <!-- footer copyright -->
</footer>
