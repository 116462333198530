import {ShoppingCartProductWrapper} from "../shoppingcart/ShoppingCartProduct";
import {ShoppingCartModificators} from "../shoppingcart/ShoppingCartModificators";

export class OrderRequest {
  shopBranchId: number | null;
  paidByBonus: number;
  type: string;
  address: string;
  deliveryAreaId: number | null;
  userNote: string;
  productOrders?: (ProductOrdersEntity)[] | null;
  payType?: string | null;

  constructor(scpw: ShoppingCartProductWrapper[],
              shopBranchId: number,
              paidByBonus: number,
              type: string,
              address: string,
              deliveryAreaId: number,
              userNote: string,
              payType: string) {
    this.shopBranchId = shopBranchId;
    this.paidByBonus = paidByBonus;
    this.type = type;
    this.address = address;
    this.deliveryAreaId = deliveryAreaId;
    this.userNote = userNote;
    this.payType = payType;

    this.productOrders = [];
    scpw.forEach(item => {
      let productOrdersEntity: ProductOrdersEntity = new ProductOrdersEntity(item);
      this.productOrders.push(productOrdersEntity);
    });
  }

}
export class ProductOrdersEntity {
  productId: number;
  count: number;
  modifierOrders?: (ModifierOrdersEntity)[] | null;

  constructor(scpw: ShoppingCartProductWrapper) {
    this.productId = scpw.shoppingCartProduct.id;
    this.count = scpw.count;

    this.modifierOrders = [];
    if(scpw.shoppingCartProduct.shoppingCartModificators) {
      scpw.shoppingCartProduct.shoppingCartModificators.forEach(modifItem => {
        let modif: ModifierOrdersEntity = new ModifierOrdersEntity(modifItem);
        if (modifItem.count !== 0) {
          this.modifierOrders.push(modif)
        }
      });

    }
  }
}

export class ModifierOrdersEntity {
  modifierId: number;
  count: number;

  constructor(smod: ShoppingCartModificators){
    this.modifierId = smod.id;
    this.count = smod.count;
  }
}
