import {Component, Input, OnInit} from '@angular/core';
import {ModifiersEntity, ProductResponse} from '../../../model/product/ProductResponse';

@Component({
  selector: 'app-modificator-product',
  templateUrl: './modificator-product.component.html',
  styleUrls: ['./modificator-product.component.css']
})
export class ModificatorProductComponent implements OnInit {

  modificator: ModifiersEntity;
  product: ProductResponse;

  priceOneCount: number;

  @Input('modificator')
  set setModif(modificator: ModifiersEntity) {
    this.modificator = modificator;
    this.priceOneCount = this.modificator.price;
    if (!this.modificator.countForOrder) {
      this.modificator.countForOrder = 0;
    }
    if (!this.modificator.priceForOrder) {
      this.modificator.priceForOrder = 0;
    }
  }

  @Input('product')
  set setProduct(product: ProductResponse) {
    this.product = product;
  }


  constructor() {
  }

  ngOnInit(): void {
  }

  addModificator(modificator: ModifiersEntity) {
    if (this.modificator.countForOrder < this.modificator.maxCount) {
      this.modificator.countForOrder++;
      this.modificator.priceForOrder = Number((this.modificator.priceForOrder + this.priceOneCount).toFixed(2));
      this.product.price = Number((this.product.price + this.priceOneCount).toFixed(2));
    }
  }

  minusModificator(modificator: ModifiersEntity) {
    if (this.modificator.countForOrder >= 1) {
      this.modificator.countForOrder--;
      this.modificator.priceForOrder = Number((this.modificator.priceForOrder - this.priceOneCount).toFixed(2));
      this.product.price = Number((this.product.price - this.priceOneCount).toFixed(2));
    }
  }

}
