<mat-tab-group color="accept" mat-align-tabs="center"
               class="remove-border-bottom" (selectedTabChange)="onTabClick($event)">

  <li *ngFor="let itemGroup of groupItems; let x = index;">

    <mat-tab label="{{itemGroup.name}}">
      <ng-template matTabContent>

        <app-cardproduct [groupId] = itemGroup.id></app-cardproduct>

      </ng-template>
    </mat-tab>


  </li>


</mat-tab-group>
