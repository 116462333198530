export const environment = {
  production: true,
  shopPrefix: '3pizza',

  titleGeneral: 'Хочешь пиццу?',
  titleTwoStrGeneral: 'Закажи трёхэтажную',
  descriptionGeneral: 'Впервые в столице Сибири',
  infoCompaniFooter: 'ООО "ЕРА" Адрес: г. Новосибирск, ул. Благовещенская 48/1 подъезд 9. ИНН 5408022063 КПП 540801001',
  adressFooter: 'г. Новосибирск, ул. Благовещенская 48/1 офис 9',
  instagramFooter: 'www.instagram.com/3pizzaru/',
  phoneFooter: '+7 (913) 755-07-04',
  callPhone: '+79137550704',
  descriptionDownload: 'Хочешь пиццу? Закажи трёхэтажную! Наши пиццы смотрятся великолепно на любом столе, столике и даже на тумбочке. Вы с друзьями - трёхэтажная пицца. Корпоратив - трёхэтажная пицца. Хочется вкусненького домой - трёхэтажная пицца. Такого в Новосибирске ещё не было, заказывай сейчас!',
  mailFooter: 'info@food-assist.ru',
  terminal: '1608538562535',
  linkAppAndroid: 'https://3-pizza.ru/android',
  linkAppIos: 'https://3-pizza.ru/ios',

  apiBaseUrl : 'http://89.208.223.43:8080/food',
  ws: 'ws://89.208.223.43:8080/food/ws'
  // apiBaseUrl : 'https://foodassist.ru:8443/food',
  // ws: 'https://foodassist.ru:8443/food/ws'
};
