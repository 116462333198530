<!doctype html>
<html class="no-js" lang="en">

<head>
  <meta charset="utf-8">

  <!--====== Title ======-->
  <title>AppLand - App Landing Page Template</title>

  <meta name="description" content="">
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <!--====== Favicon Icon ======-->
  <link rel="shortcut icon" href="../assets/images/favicon.png" type="image/png">


</head>

<body>
<!--[if IE]>
<p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade
  your browser</a> to improve your experience and security.</p>
<![endif]-->

<div class="header-shape-left">
  <img src="../assets/images/header-shape-2.svg" alt="shape">
</div>
<!--====== HEADER PART START ======-->
<header class="header-area">
  <div class="navbar-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="/">
              <img src="../assets/images/logo.png" alt="Logo" height="40">
            </a>
            <button style="margin-right: -160px;" mat-button [matMenuTriggerFor]="menu" class="navbar-toggler"
                    type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <mat-icon style="color: black;">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a class="page-scroll" href="#why" style="width: 100%">
                <button mat-menu-item>
                  <mat-icon>restaurant_menu</mat-icon>
                  <span>Меню</span>
                </button>
              </a>

              <br>
              <a class="page-scroll" href="#download" style="width: 100%">
                <button mat-menu-item>
                  <mat-icon>apps</mat-icon>
                  <span>Приложение</span>
                </button>
              </a>

              <br>
              <a class="page-scroll" href="#footer" style="width: 100%">
                <button mat-menu-item>
                  <mat-icon>contact_phone</mat-icon>
                  <span>Контакты</span>
                </button>
              </a>


              <a class="page-scroll" (click)="openShopCart()" style="width: 100%">
                <button mat-menu-item>
                  <mat-icon>shopping_cart</mat-icon>
                  <span>Корзина</span>
                </button>
              </a>
            </mat-menu>

            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
              <ul id="nav" class="navbar-nav ml-auto">
                <li class="nav-item menu-batton">
                  <a class="page-scroll" href="#home">Главная</a>
                </li>
                <li class="nav-item menu-batton">
                  <a class="page-scroll" href="#why">Меню</a>
                </li>
                <li class="nav-item menu-batton">
                  <a class="page-scroll" href="#download">Приложение</a>
                </li>
                <li class="nav-item menu-batton">
                  <a class="page-scroll" href="#footer">Контакы</a>
                </li>

                <li class="nav-item menu-batton">
                  <a class="page-scroll"
                     style="cursor: pointer;"
                     (click)="openShopCart()">Корзина</a>
                </li>
              </ul>
            </div> <!-- navbar collapse -->
          </nav> <!-- navbar -->
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
  </div> <!-- navbar area -->

  <div id="home" class="header-hero bg_cover d-lg-flex align-items-center">

    <div class="shape shape-1"></div>
    <div class="shape shape-2"></div>
    <div class="shape shape-3"></div>
    <div class="shape shape-4"></div>
    <div class="shape shape-5"></div>
    <div class="shape shape-6"></div>

    <div class="container">
      <div class="row align-items-center justify-content-center justify-content-lg-between">
        <div class="col-lg-6 col-md-10">
          <div class="header-hero-content">
            <h3 class="header-title wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.2s">{{titleGeneral}}<br>{{titleTwoStrGeneral}}</h3>
            <p class="text wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.6s">{{descriptionGeneral}}<br></p>
          </div> <!-- header hero content -->
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-10">
          <div class="header-image">
            <img src="../assets/images/header-app.png" alt="app" class="image wow fadeInRightBig"
                 data-wow-duration="1.3s" data-wow-delay="0.5s">
          </div> <!-- header image -->
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
    <div class="header-shape-1"></div> <!-- header shape -->
    <!-- header shape -->
  </div> <!-- header hero -->
</header>

<!--====== HEADER PART ENDS ======-->

<!--====== SERVICES PART START ======-->

<section id="why" class="services-area pt-110 pb-120">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div class="section-title text-center pb-25">
          <h3 class="title">Трехэтажные пиццы</h3>
        </div> <!-- section title -->
      </div>
    </div> <!-- row -->
    <div class="row">

      <app-products
        [shopSettings]=shopSettings
        style="width: 100%"></app-products>

    </div> <!-- row -->
  </div> <!-- container -->
</section>

<!--====== SERVICES PART ENDS ======-->

<!--====== DOWNLOAD PART START ======-->

<app-downoladapp></app-downoladapp>

<!--====== DOWNLOAD PART ENDS ======-->
<!--====== PART START ======-->

<div class="float">
  <button mat-fab color="primary"
          *ngIf="floatButtonShopCartVisible"
          matBadge="{{countShopCartOrder}}"
          matBadgeColor="accent"
          (click)="openShopCart()"
  >
    <mat-icon>shopping_cart</mat-icon>
  </button>
</div>

<app-footer></app-footer>

<!--====== PART ENDS ======-->
<!--====== BACK TOP TOP PART START ======-->

<a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>

<!--====== BACK TOP TOP PART ENDS ======-->

<!--====== PART ENDS ======-->


</body>

</html>
