import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {ProductDAO} from '../../interface/product/ProductDAO';
import {Observable} from 'rxjs';
import {ProductResponse} from '../../../model/product/ProductResponse';

@Injectable({
  providedIn: 'root'
})
export class ProductService implements ProductDAO {

  url = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  getAllByGroup(productGroupId: number): Observable<ProductResponse[]> {
    return this.http.get<ProductResponse[]>('/api/user/product/group?productGroupId=' + productGroupId);
  }
}
