<div class="row row-paddings" style="margin-top: 24px;">
  <div class="col-lg-4 col-md-6 col-sm-12 col-xl-3 col-paddings" *ngFor="let itemProduct of productList; let i = index">

    <mat-card class="example-card" style="margin-top: 16px; cursor: pointer; z-index: 5;" (click)="$event.stopPropagation(); onCurrentItemClick(i);">

      <!--<img class="img-crop" mat-card-image src="{{itemProduct.productPhotos[0] != null ? getUrlPhoto(itemProduct.productPhotos[0].path)-->
      <!--: '../assets/images/product.svg'}}" alt="продукт">-->
      <img class="img-crop" mat-card-image [defaultImage]="'../assets/images/loader.gif'" [lazyLoad]="itemProduct.productPhotos[0] != null ? getUrlPhoto(itemProduct.productPhotos[0].path) : '../assets/images/product.jpg'" alt="продукт">
      <mat-card-content>
        <p class="name-product">{{itemProduct.name}}</p>
        <p  *ngIf="itemProduct.unit" class="weight-product">{{itemProduct.weight}} {{itemProduct.unit.name}}</p>
        <p class="descr-product">
          {{getDescription(itemProduct.description, i)}}
        </p>


        <div *ngIf="currentIndex === i">


          <div *ngFor="let itemModificator of itemProduct.modifiers; let m = index">

            <app-modificator-product
              [modificator]="itemModificator"
              [product]="itemProduct"
            ></app-modificator-product>

          </div>
        </div>


      </mat-card-content>
      <mat-card-actions style="margin-bottom: 28px;">
        <p class="float-left price-product">₽ {{itemProduct.price}}</p>
        <button style="z-index: 10;" type="button" class="btn btn-warning float-right button-add"

                (click)="$event.stopPropagation(); onAddClick(itemProduct)"
        >

          Добавить
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
