import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ProductsComponent} from './views/product/products/products.component';
import {FooterComponent} from './views/footer/footer.component';
import {DownoladappComponent} from './views/downoladapp/downoladapp.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptorService} from './service/interceptor.service';
import { CardproductComponent } from './views/product/cardproduct/cardproduct.component';
import { ModificatorProductComponent } from './views/product/modificator-product/modificator-product.component';
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import { ShopCartDialogComponent } from './dialog/shop-cart-dialog/shop-cart-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {MatOptionModule} from "@angular/material/core";
import { PaymentOkDialogComponent } from './dialog/payment-ok-dialog/payment-ok-dialog.component';
import {MatMenuModule} from "@angular/material/menu";
import { AnyDialogComponent } from './dialog/any-dialog/any-dialog.component';
import {MetrikaModule} from "ng-yandex-metrika";
import { AnyDialogHtmlComponent } from './dialog/any-dialog-html/any-dialog-html.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    FooterComponent,
    DownoladappComponent,
    CardproductComponent,
    ModificatorProductComponent,
    ShopCartDialogComponent,
    PaymentOkDialogComponent,
    AnyDialogComponent,
    AnyDialogHtmlComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    MatIconModule,
    MatBadgeModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    NgxMaskModule.forRoot(),
    MatMenuModule,
    MetrikaModule.forRoot(
      {id: 69824581, webvisor: true}, // CounterConfig | CounterConfig[]
      // Можно задать ид счетчика, либо порядковый номер в массиве, необязательный параметрб по умолчанию первый попавшийся.
      10, // number | string
    ),
    LazyLoadImageModule,
    MatButtonToggleModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }, {
    provide: LAZYLOAD_IMAGE_HOOKS,
    useClass: ScrollHooks,
  }],
  entryComponents: [
    ShopCartDialogComponent,
    PaymentOkDialogComponent,
    AnyDialogComponent,
    AnyDialogHtmlComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
