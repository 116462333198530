import {Injectable} from '@angular/core';
import {OrderDAO} from "../../interface/order/OrderDAO";
import {Observable} from "rxjs";
import {OrderResponse} from "../../../model/order/OrderResponse";
import {OrderRequest} from "../../../model/order/OrderRequest";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class OrderService implements OrderDAO{

  constructor(private http: HttpClient) { }

  createOrder(orderRequest: OrderRequest): Observable<OrderResponse> {
    return this.http.post<OrderResponse>('/api/user/order', orderRequest);
  }

}
