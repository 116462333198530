import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-any-dialog-html',
  templateUrl: './any-dialog-html.component.html',
  styleUrls: ['./any-dialog-html.component.css']
})
export class AnyDialogHtmlComponent implements OnInit {

  dialogTitle: string;
  message: string;

  constructor(private dialogRef: MatDialogRef<AnyDialogHtmlComponent>,
              @Inject(MAT_DIALOG_DATA) private data: [string, string]) { }

  ngOnInit(): void {
    this.dialogTitle = this.data[0];
    this.message = this.data[1];
  }

  confirm() {
    this.dialogRef.close();
  }


}
