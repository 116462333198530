import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {catchError} from 'rxjs/operators';
import {AuthService} from "../dao/impl/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  shopPrefix = environment.shopPrefix;
  public currentUser;

  constructor(private authService: AuthService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqUrl = environment.apiBaseUrl;
    if (!req.url.includes('/api/user/product/group')
      || !req.url.includes('/api/user/productgroup')
      || !req.url.includes('/api/user/shop/')
      || !req.url.includes('/api/user/auth/getsms4')
      || !req.url.includes('/api/user/auth/sendsms')
      || !req.url.includes('/api/user/branch')
    ) {
      this.currentUser = localStorage.getItem(this.shopPrefix + 'currentUser') ? JSON.parse(localStorage.getItem(this.shopPrefix + 'currentUser')) : '';
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this.currentUser.accessToken
        ),
        url: reqUrl + '' + req.url,
      });
    } else {
      req = req.clone({
        url: reqUrl + '' + req.url,
      });
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        return throwError(err);
      })
    );
  }
}
