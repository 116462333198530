import {Component, OnInit} from '@angular/core';
import {ShoppingCartService} from "./service/shopping-cart.service";
import {ToastrService} from "ngx-toastr";
import {ShoppingCartProductWrapper} from "./model/shoppingcart/ShoppingCartProduct";
import {MatDialog} from "@angular/material/dialog";
import {ShopCartDialogComponent} from "./dialog/shop-cart-dialog/shop-cart-dialog.component";
import {ShopService} from "./dao/impl/shop/shop.service";
import {ShopResponse} from "./model/shop/ShopResponse";
import {AreaDeliveryService} from "./dao/impl/areadelivey/area-delivery.service";
import {AreaDeliveryResponse} from "./model/areadelivey/AreaDeliveryResponse";
import {AuthService} from "./dao/impl/auth/auth.service";
import {ShopBranchResponse} from "./model/shop/ShopBranchResponse";
import {ShopBranchesService} from "./dao/impl/shop/shop-branches.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PaymentOkDialogComponent} from "./dialog/payment-ok-dialog/payment-ok-dialog.component";
import {Metrika} from "ng-yandex-metrika";
import {environment} from "../environments/environment.prod";
import {AnyDialogComponent} from "./dialog/any-dialog/any-dialog.component";
import {AnyDialogHtmlComponent} from "./dialog/any-dialog-html/any-dialog-html.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Трехэтажная пицца';

  titleGeneral = environment.titleGeneral;
  titleTwoStrGeneral = environment.titleTwoStrGeneral;
  descriptionGeneral = environment.descriptionGeneral;

  floatButtonShopCartVisible: boolean = false;
  countShopCartOrder: number;

  shopSettings: ShopResponse;
  areasDelivery: AreaDeliveryResponse[];
  shopBranches: ShopBranchResponse[];
  private querySubscription: Subscription;

  constructor(private shoppingCartService: ShoppingCartService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private shopService: ShopService,
              private areaDeliveryService: AreaDeliveryService,
              private authService: AuthService,
              private shopBranchesService: ShopBranchesService,
              private route: ActivatedRoute) {

    this.querySubscription = route.queryParams.subscribe(
      (queryParam: any) => {
        if (queryParam['payment'] == '1') {
          const dialogRef = this.dialog.open(PaymentOkDialogComponent, {
            disableClose: true
          });
        }
        if (queryParam['prize'] == '1') {
          const dialogRef = this.dialog.open(AnyDialogHtmlComponent, {
            maxWidth: '700px',
            data: ['Условия розыгрыша', '<!-- #######  YAY, I AM THE SOURCE EDITOR! #########-->\n' +
            '<p class="p1" style="text-align: justify;">- Розыгрыш проводится ООО "ЕРА" "Трехэтажная пицца".</p>\n' +
            '<p class="p1" style="text-align: justify;">- Розыгрыш проводится с 12:00 06.02.2021 по 23:59 13.02.2021</p>\n' +
            '<p class="p1" style="text-align: justify;">- В розыгрыше имеют право принимать лица достигшие 18 лет.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
            '<p class="p1" style="text-align: justify;">- Победители определятся путём выбора рандомайзером из числа участвующих в прямом эфире (наш инстаграм). Всего будет выбрано 10 аккаунтов.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
            '<p class="p1" style="text-align: justify;">- Каждый из победителей может сделать заказ на одну бесплатную трехэтажную пиццу.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
            '<p class="p1" style="text-align: justify;">- Результаты будут объявлены 14.02.2021 с 10:00 до 11:00 по Новосибирскому времени<span class="Apple-converted-space">&nbsp; </span>на странице (наш инстаграмм)</p>\n' +
            '<p class="p1" style="text-align: justify;">- Воспользоваться возможностью заказа и доставки пиццы можно с 14.02.2021 по 21.02.2021. Для этого необходимо связаться с администратором ресторана по номеру телефона +79137550704, верифицировать Ваш аккаунт и сделать заказ.</p>\n' +
            '<p class="p1" style="text-align: justify;">- Заказы на доставку принимаются минимум за 4 часа до желаемого времени доставки.<span class="Apple-converted-space">&nbsp;</span></p>\n' +
            '<p class="p1" style="text-align: justify;">- Доставка выигранных заказов производится по территории г. Новосибирск, г. Бердск, р.п. Кольцово с 10:00 до 20:00 с 14.02.2021 по 21.02.2021г.</p>'],
          });
        }
      }
    );
    this.visibleOrHideFloatShopButton(this.shoppingCartService.shopingCartProductsWrapper);
  }

  ngOnInit(): void {
    this.shoppingCartService.changeEmittedShoppingCart$.subscribe(data => {
        console.log('changeEmittedShoppingCart$ ' + data.length);
        this.visibleOrHideFloatShopButton(data);
      },
      error => {
        this.toastr.error(error.message, 'Error');
      });
    this.authService.checkauth().subscribe(data => {
      console.log('data ' + data);
    });
    this.getShopSettings();
    this.getAreaDelivery();
  }

  getShopBranches(shopId: number) {
    this.shopBranchesService.getAllBranches(shopId).subscribe(data => {
      this.shopBranches = data;
    })
  }

  visibleOrHideFloatShopButton(floatButtonShopCartVisible: ShoppingCartProductWrapper[]) {
    this.floatButtonShopCartVisible = floatButtonShopCartVisible.length !== 0;
    this.countShopCartOrder = this.shoppingCartService.countShopCart;
  }

  openShopCart() {
    const dialogRef = this.dialog.open(ShopCartDialogComponent, {
      data: [this.shopSettings, this.areasDelivery, this.shopBranches],
      width: '100%',
      height: '100%',
      disableClose: true
    });
  }

  // получение настроек магазина
  getShopSettings() {
    this.shopService.getShop().subscribe(data => {
        this.shopSettings = data;
        this.getShopBranches(this.shopSettings.id)
      },
      error => {
        this.toastr.error(error.message, 'Error');
      });
  }

  //  получение районов доставки
  getAreaDelivery() {
    this.areaDeliveryService.getAllAreaDelivery().subscribe(data => {
        this.areasDelivery = data;
      },
      error => {
        this.toastr.error(error.message, 'Error');
      });
  }




}
