import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ShoppingCartService} from "../../service/shopping-cart.service";

@Component({
  selector: 'app-payment-ok-dialog',
  templateUrl: './payment-ok-dialog.component.html',
  styleUrls: ['./payment-ok-dialog.component.css']
})
export class PaymentOkDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PaymentOkDialogComponent>,
              private router: Router,
              private shoppingCartService: ShoppingCartService) { }

  ngOnInit(): void {
  }

  clickOk() {
    this.shoppingCartService.deleteAllSoppingCart();
    this.router.navigateByUrl("");
    this.dialogRef.close();
  }
}
