<div class="row" style="margin-left: -30px; margin-top: 5px;">
  <div class="col-4">
    <div class="col-12" style="font-size: 9pt;">{{modificator.name}}</div>
    <div class="col-12" style="font-size: 6pt;">(max {{modificator.maxCount}})</div>
  </div>
  <div class="col-4" style="text-align: center; font-size: 12pt">{{this.modificator.priceForOrder}}₽</div>
  <div class="col-4" style="text-align: center; font-size: 13pt">
    <div class="row float-right">
      <div style="z-index: 10;" (click)="$event.stopPropagation(); minusModificator(modificator)"> - </div>
       <div style="margin-right: 5px; margin-left: 5px;">{{this.modificator.countForOrder}}</div>
      <div (click)="$event.stopPropagation(); addModificator(modificator)" style="z-index: 10; margin-right: 10px;"> + </div>
    </div>
  </div>
</div>
