import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from '../../../dao/impl/product/product.service';
import {ProductResponse} from '../../../model/product/ProductResponse';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment.prod';
import {ShoppingCartService} from '../../../service/shopping-cart.service';
import {ShoppingCartProduct} from '../../../model/shoppingcart/ShoppingCartProduct';
import {ShoppingCartModificators} from '../../../model/shoppingcart/ShoppingCartModificators';

@Component({
  selector: 'app-cardproduct',
  templateUrl: './cardproduct.component.html',
  styleUrls: ['./cardproduct.component.css']
})
export class CardproductComponent implements OnInit {


  groupId: number;
  currentIndex = -1;
  lastIndex = -1;

  @Input('groupId')
  set setGroupId(groupId: number) {
    this.groupId = groupId;
    this.getProductsForGroup(this.groupId);
  }

  url = environment.apiBaseUrl;

  productList: ProductResponse[];

  constructor(private productService: ProductService,
              private toastr: ToastrService,
              private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit(): void {
  }

  // получение списка товаров по id группы
  getProductsForGroup(productGroupId: number) {
    this.productService.getAllByGroup(productGroupId).subscribe(data => {
        this.productList = data;
      },
      error => {
        this.toastr.error(error.message, 'Error');
      });
  }

  onAddClick(product: ProductResponse) {
    const shoppingCartProduct = new ShoppingCartProduct();
    shoppingCartProduct.id = product.id;
    shoppingCartProduct.name = product.name;
    shoppingCartProduct.price = product.price;
    shoppingCartProduct.unit = product.unit;
    shoppingCartProduct.weight = product.weight;
    shoppingCartProduct.description = product.description;
    shoppingCartProduct.productPhotos = product.productPhotos;
    const shoppingCartModificators = [];
    product.modifiers.forEach(mod => {
      const shoppingCartModificator = new ShoppingCartModificators(mod.id, mod.name, mod.priceForOrder, mod.countForOrder);

      shoppingCartModificators.push(shoppingCartModificator);

    });
    shoppingCartProduct.shoppingCartModificators = shoppingCartModificators;
    this.shoppingCartService.addShoppingCartProduct(shoppingCartProduct);
  }

  onCurrentItemClick(index: number) {
    this.currentIndex = index;
    if (this.currentIndex == this.lastIndex) {
      this.currentIndex = -1;
      this.lastIndex = -2;
    } else {
      this.lastIndex = index;
    }
  }

  getUrlPhoto(path: string): string {
    return this.url + path;
  }

  getDescription(str: string, indx: number) {
    if (str.length < 50) {
      return str;
    } else {
      if (this.currentIndex === indx) {
        return str;
      } else {
        return str.substring(0, 55) + '...';
      }
    }
  }

}
