import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ShopResponse} from "../../model/shop/ShopResponse";
import {AreaDeliveryResponse} from "../../model/areadelivey/AreaDeliveryResponse";
import {ShopBranchResponse} from "../../model/shop/ShopBranchResponse";

@Component({
  selector: 'app-any-dialog',
  templateUrl: './any-dialog.component.html',
  styleUrls: ['./any-dialog.component.css']
})
export class AnyDialogComponent implements OnInit {


  dialogTitle: string;
  message: string;

  constructor(private dialogRef: MatDialogRef<AnyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: [string, string]) { }

  ngOnInit(): void {
    this.dialogTitle = this.data[0];
    this.message = this.data[1];
  }

  confirm() {
    this.dialogRef.close();
  }

}
