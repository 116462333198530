<!--диалоговое окно -->

<h1 mat-dialog-title>
  {{dialogTitle}}
</h1>

<div mat-dialog-content>
  <p class="text-style">{{message}}</p>
</div>

<!--  кнопки возможных действий-->
<section>
  <div>
    <button (click)="confirm()"
            class="float-right button-ok"
            mat-raised-button>ОК</button>
  </div>
</section>
