import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-downoladapp',
  templateUrl: './downoladapp.component.html',
  styleUrls: ['./downoladapp.component.css']
})
export class DownoladappComponent implements OnInit {

  descriptionDownload = environment.descriptionDownload;
  linkAppAndroid = environment.linkAppAndroid;
  linkAppIos = environment.linkAppIos;

  constructor() { }

  ngOnInit(): void {
  }

}
