import { Injectable } from '@angular/core';
import {AreaDeliveryDAO} from "../../interface/areadelivery/AreaDeliveryDAO";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AreaDeliveryResponse} from "../../../model/areadelivey/AreaDeliveryResponse";
import {environment} from "../../../../environments/environment.prod";
import {ShopResponse} from "../../../model/shop/ShopResponse";

@Injectable({
  providedIn: 'root'
})
export class AreaDeliveryService implements AreaDeliveryDAO{

  url = environment.apiBaseUrl;
  shopPrefix = environment.shopPrefix;

  constructor(private http: HttpClient) { }

  getAllAreaDelivery(): Observable<AreaDeliveryResponse[]> {
    return this.http.get<AreaDeliveryResponse[]>('/api/area/getall/' + this.shopPrefix);
  }
}
