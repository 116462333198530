<section id="download" class="download-area pt-70 pb-40">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6 col-md-9">
        <div class="download-image mt-50 wow fadeInRightBig" data-wow-duration="1.3s" data-wow-delay="0.2s">
          <img class="image" src="../assets/images/download-app.jpg" alt="download">

          <div class="download-shape-1"></div>
          <div class="download-shape-2">
            <img class="svg" src="../assets/images/download-shape.svg" alt="shape">
          </div>
        </div> <!-- download image -->
      </div>
      <div class="col-lg-6">
        <div class="download-content mt-45 wow fadeInLeftBig" data-wow-duration="1.3s" data-wow-delay="0.5s">
          <h3 class="download-title">Скачайте наше приложение!</h3>
          <p class="text">{{descriptionDownload}}</p>
          <ul>
            <li><a target="_blank"  [attr.href]="linkAppIos"><img style="height: 54px;"
                                                                  src="../assets/images/app-store.svg" alt="store"></a>
            </li>
            <li style="margin-left: -15px;"><a target="_blank"  [attr.href]="linkAppAndroid"><img
              src="../assets/images/google-play-badge.png" alt="store"></a></li>
          </ul>
        </div> <!-- download image -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</section>
