import {Injectable} from '@angular/core';
import {AuthDAO} from "../../interface/auth/AuthDAO";
import {BehaviorSubject, Observable} from "rxjs";
import {GetSmsResponse} from "../../../model/auth/GetSmsResponse";
import {SendSmsResponse} from "../../../model/auth/SendSmsResponse";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment.prod";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements AuthDAO {

  url = environment.apiBaseUrl;

  shopPrefix = environment.shopPrefix;

  private currentUserSubject: BehaviorSubject<SendSmsResponse>;
  public currentUser: Observable<SendSmsResponse>;


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<SendSmsResponse>(JSON.parse(localStorage.getItem(this.shopPrefix + 'currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): SendSmsResponse {
    return this.currentUserSubject.value;
  }

  getSms4(phoneNumber: string): Observable<GetSmsResponse> {
    return this.http.post<GetSmsResponse>('/api/user/auth/getsms4?phoneNumber=' + phoneNumber
      + '&shopPrefix=' + this.shopPrefix, null);
  }

  sendSms(phoneNumber: string, smsCode: string): Observable<SendSmsResponse> {
    return this.http.post<SendSmsResponse>('/api/user/auth/sendsms?phoneNumber=' + phoneNumber
      + '&smsCode=' + smsCode + '&shopPrefix=' + this.shopPrefix, null).pipe(map(
        user => {
          if(user) {
            localStorage.setItem(this.shopPrefix + 'currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
          } else {
            return null;
          }
        }
    ));
  }

  checkauth() {
    return this.http.get('/api/user/checkauth');
  }


  logout() {
    localStorage.removeItem(this.shopPrefix + 'currentUser');
    this.currentUserSubject.next(null);
  }
}
