import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";
import {TermsDAO} from "../../interface/terms/TermsDAO";
import {Observable} from "rxjs";
import {TermsResponse} from "../../../model/terms/TermsResponse";

@Injectable({
  providedIn: 'root'
})
export class TermsService implements TermsDAO{

  url = environment.apiBaseUrl;
  shopPrefix = environment.shopPrefix;

  constructor(private http: HttpClient) { }

  getDelivery(): Observable<TermsResponse> {
    return this.http.get<TermsResponse>('/api/user/shop/terms/delivery/' + this.shopPrefix);
  }

  getService(): Observable<TermsResponse> {
    return this.http.get<TermsResponse>('/api/user/shop/terms/service/' + this.shopPrefix);
  }

}
