import {ShoppingCartModificators} from './ShoppingCartModificators';
import {ProductPhotosEntity} from "../product/ProductResponse";

export class ShoppingCartProduct {

  id: number;
  name: string;
  price: number;
  weight: number;
  unit?: ProductGroupOrUnit | null;
  description: string;
  productPhotos?: (ProductPhotosEntity)[] | null;

  shoppingCartModificators: ShoppingCartModificators[];
}

export class ShoppingCartProductWrapper {
  count: number;
  shoppingCartProduct: ShoppingCartProduct;

  constructor(count: number, shoppingCartProduct: ShoppingCartProduct) {
    this.count = count;
    this.shoppingCartProduct = shoppingCartProduct;

  }
}

export interface ProductGroupOrUnit {
  id: number;
  name: string;
}
