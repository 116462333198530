<!--создание/редактирование категории-->
<mat-dialog-content style="margin-left: -24px; margin-right: -24px;">

  <div class="row">
    <div class="col-12 float-right">
      <button style="margin-top: 8px;" mat-button class="float-right example-button-row" (click)="close()">
        <mat-icon>close</mat-icon>
        Закрыть
      </button>
      <div class="col-12 title-shop-cart">Оформление заказа</div>
    </div>

  </div>


  <p style="margin-top: 24px; margin-bottom: 24px; font-size: 9pt;">Время приёма заказов рестораном - ежедневно с
    {{shopSetting.orderBeginTime}} до {{shopSetting.orderEndTime}}
    <br>Способы оплаты - картой на сайте, наличными или картой при доставке
  </p>

  <div class="row">

    <!--выбор типа доставки-->
    <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12">
      <mat-form-field style="width: 100%">
        <mat-label>Выберите способ доставки</mat-label>
        <mat-select (valueChange)="onSelectDelivery()" [(ngModel)]="currentDelivery" required>
          <mat-option value="DELIVERY">Доставка</mat-option>
          <mat-option value="PICKUP">Самовывоз</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--выбор адреса самовывоза-->
    <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12" *ngIf="currentDelivery === 'PICKUP'">
      <mat-form-field style="width: 100%">
        <mat-label>Выберите адрес самовывоза</mat-label>
        <mat-select [(ngModel)]="currentShopBranch" required>
          <mat-option *ngFor="let branch of shopBranches" [value]="branch">
            {{branch.address}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--выбор района доставки-->
    <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12" *ngIf="currentDelivery === 'DELIVERY'">
      <mat-form-field style="width: 100%">
        <mat-label>Выберите район доставки</mat-label>
        <mat-select (valueChange)="onSelectedDeliveryArea($event)" [(ngModel)]="currentAreaDelivery" required>
          <mat-option *ngFor="let area of areasDelivery" [value]="area">
            {{area.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <!--ввод телефона для заказа-->
    <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">

      <mat-form-field style="width: 100%">
        <mat-label>Телефон</mat-label>
        <input
          #inputPhone

          prefix="+7"
          mask="(000) 000 00 00"
          matInput maxlength="17"

          [(ngModel)]="phoneNow"
          required

        />
        <button
          *ngIf="nameNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="phoneNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <div hidden class="alert alert-danger">
        Не указан телефон
      </div>

    </div>

    <!--ввод имени-->
    <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">

      <mat-form-field style="width: 100%">
        <mat-label>Ваше имя</mat-label>
        <input
          #inputName
          matInput maxlength="50"
          [(ngModel)]="nameNow"
        />
        <button
          *ngIf="nameNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="nameNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>

    </div>


    <!--ввод улицы-->
    <div class="col-sm-12 col-md-12 col-xl-12 col-lg-12" *ngIf="currentDelivery === 'DELIVERY'">

      <mat-form-field style="width: 100%">
        <mat-label>Улица</mat-label>
        <input
          #inputStreet
          matInput maxlength="254"

          [(ngModel)]="streeNow"
          required

        />
        <button
          *ngIf="streeNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="streeNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>

    </div>


    <!--ввод дома-->
    <div class="col-sm-6 col-md-3 col-xl-3 col-lg-3" *ngIf="currentDelivery === 'DELIVERY'">
      <mat-form-field style="width: 100%">
        <mat-label>Дом</mat-label>
        <input
          #inputHouse
          matInput maxlength="254"
          [(ngModel)]="houseNow"

        />
        <button
          *ngIf="houseNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="houseNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>


    <!--ввод квартиры-->
    <div class="col-sm-6 col-md-3 col-xl-3 col-lg-3" *ngIf="currentDelivery === 'DELIVERY'">
      <mat-form-field style="width: 100%">
        <mat-label>Квартира/офис</mat-label>
        <input
          #inputApartment
          matInput maxlength="254"

          [(ngModel)]="apartmentNow"

        />
        <button
          *ngIf="apartmentNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="apartmentNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!--ввод подъезда-->
    <div class="col-sm-6 col-md-3 col-xl-3 col-lg-3" *ngIf="currentDelivery === 'DELIVERY'">
      <mat-form-field style="width: 100%">
        <mat-label>Подъезд</mat-label>
        <input
          #inputEntrance
          matInput maxlength="254"
          [(ngModel)]="entranceNow"

        />
        <button
          *ngIf="entranceNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"

          (click)="entranceNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!--ввод этажа-->
    <div class="col-sm-6 col-md-3 col-xl-3 col-lg-3" *ngIf="currentDelivery === 'DELIVERY'">
      <mat-form-field style="width: 100%">
        <mat-label>Этаж</mat-label>
        <input
          #inputFloor
          matInput maxlength="254"
          [(ngModel)]="floorNow"

        />
        <button
          *ngIf="floorNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="floorNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>


    <!--коммент к заказу-->
    <div class="col-sm-12 col-md-12 col-xl-12 col-lg-12">

      <mat-form-field style="width: 100%">
        <mat-label>Комментарий к заказу</mat-label>
        <input
          #inputComment
          matInput maxlength="254"
          [(ngModel)]="commentNow"

        />
        <button
          *ngIf="commentNow.length > 0"

          class="clear-icon"
          mat-button matSuffix mat-icon-button aria-label="Clear"
          (click)="commentNow= ''"

        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>

      <div *ngIf="shopSetting.cashPayment || shopSetting.terminalPayment">
        <p class="title-payment">Заказ будет оплачен:</p>
        <mat-button-toggle-group #group="matButtonToggleGroup"
                                 [value]="currentPaymentType"
                                 (change)='onValChangePayment(group.value)'>
          <mat-button-toggle value="onlinePayment" aria-label="Text align left">
            <mat-icon>language</mat-icon>
            Онлайн
          </mat-button-toggle>
          <mat-button-toggle *ngIf="shopSetting.cashPayment" value="cashPayment" aria-label="Text align center">
            <mat-icon>attach_money</mat-icon>
            Наличные
          </mat-button-toggle>
          <mat-button-toggle *ngIf="shopSetting.terminalPayment" value="terminalPayment" aria-label="Text align right">
            <mat-icon>credit_card</mat-icon>
            Картой
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      </div>

  </div>


  <!--Секция с блюдами-->
  <div style="margin-top: 24px;" *ngFor="let itemProduct of shopingCartProductsWrapper; let i = index">
    <button class="example-button-delete" *ngIf="itemProduct.count !== 0"
            mat-mini-fab style="align-self: center"
            (click)="deleteAllProductShopCart(itemProduct.shoppingCartProduct)">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-card class="example-card" style="margin-top: 24px;" *ngIf="itemProduct.count !== 0">
      <mat-card-content>
        <div class="row">
          <div class="col-lg-3 col-sm-12 col-xl-3 col-md-3">
            <img class="img-crop" src="{{itemProduct.shoppingCartProduct.productPhotos[0] != null ? getUrlPhoto(itemProduct.shoppingCartProduct.productPhotos[0].path)
      : '../assets/images/product.svg'}}" alt="продукт">
          </div>
          <div class="col-lg-5 col-sm-12 col-xl-5 col-md-5" style="align-self: center;">
            <p class="name-product">{{itemProduct.shoppingCartProduct.name}}</p>
            <p *ngIf="itemProduct.shoppingCartProduct.unit" class="weight-product">{{itemProduct.shoppingCartProduct.weight}} {{itemProduct.shoppingCartProduct.unit.name}}</p>
            <p class="descr-product">{{itemProduct.shoppingCartProduct.description}}</p>
            <p class="descr-product">{{getModificatorsString(itemProduct.shoppingCartProduct)}}</p>
          </div>
          <div class="col-lg-2 col-sm-2 col-xl-2 col-md-2" style="align-self: center;">


            <div class="example-button-row">
              <button mat-button style="min-width: 20px;" (click)="addProductShopCart(itemProduct.shoppingCartProduct)">
                +
              </button>
              <button disabled mat-button style="min-width: 20px; color: black">{{itemProduct.count}}</button>
              <button mat-button style="min-width: 20px;" [disabled]="itemProduct.count === 1"
                      (click)="deleteProductShopCart(itemProduct.shoppingCartProduct)">-
              </button>


            </div>

          </div>
          <div class="col-lg-2 col-sm-2 col-xl-2 col-md-2 float-right" style="align-self: center;">
            <p class="name-product">₽ {{getPrice(itemProduct.shoppingCartProduct.price*itemProduct.count)}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!--Секция итого за заказ-->
  <div class="row" style="margin-top: 54px;">
    <div class="col-6 total-sum">Итого к оплате</div>
    <div class="col-6 total-sum" style="text-align: right; ">₽ {{getSumShopCart()}}</div>
    <div style="margin-top: 24px; font-size: 14pt;"
         *ngIf="getSumShopCart() < minPaymentOrder && (shopingCartProductsWrapper.length !== 0)"
         class="col-12 alert alert-danger">
      <div *ngIf="(minPaymentOrder > 0) && (currentDelivery === 'DELIVERY')" style="text-align: center;">Минимальная
        сумма заказа для текущего района доставки ₽ {{minPaymentOrder}}
      </div>
      <div>
        <button class="button-back-menu"
                (click)="backMenu()"
                mat-stroked-button>
          <mat-icon>arrow_back</mat-icon>
          Вернуться в меню
        </button>
      </div>
    </div>


    <div style="margin-top: 24px; font-size: 14pt;" *ngIf="shopingCartProductsWrapper.length == 0"
         class="col-12 alert alert-danger">

      <div style="text-align: center;">Корзина пуста</div>
      <div>
        <button class="button-back-menu"
                (click)="backMenu()"
                mat-stroked-button>
          <mat-icon>arrow_back</mat-icon>
          Вернуться в меню
        </button>
      </div>
    </div>
  </div>


  <!--нативная форма оплаты тинькофф-->
  <form #myForm="ngForm" name="TinkoffPayForm" onsubmit="pay(this); return true;">
    <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="{{terminal}}">
    <input class="tinkoffPayRow" type="hidden" name="frame" value="false">
    <input class="tinkoffPayRow" type="hidden" name="language" value="ru">
    <input class="tinkoffPayRow" type="hidden" placeholder="Сумма заказа" name="amount" [value]="getSumShopCart()"
           required>
    <input class="tinkoffPayRow" type="hidden" placeholder="Номер заказа" name="order" [value]="currentUUID">
    <input class="tinkoffPayRow" type="hidden" placeholder="ФИО плательщика" name="name"
           [value]="nameNow === '' ? 'Не указано' : nameNow">
    <input class="tinkoffPayRow" type="hidden" placeholder="Контактный телефон" name="phone" [value]="phoneNow">
    <button hidden #btc></button>
  </form>

  <!--кнопка оплатить-->
  <div class="row" *ngIf="isAuth && shopingCartProductsWrapper.length > 0">
    <button
      [disabled]="validationPaymentButton() && paymentIsBlock == false"
      mat-button style="width: 250px;"
      class="button-payment"

      (click)="createOrderAndPayment(btc)"
    >{{textButtonOrder}}
    </button>
  </div>

  <!--Поле для ввода телефона-->
  <div class="row" *ngIf="(currentState === smsGetState) && (!isAuth)">
    <mat-form-field style="width: 250px;" class="accept-phone-code">
      <mat-label>Подтвердите Ваш номер</mat-label>
      <input
        #inputPhoneForSmsCode
        prefix="+7"
        mask="(000) 000 00 00"
        matInput maxlength="17"
        required
        [(ngModel)]="phoneForSms"
      />
    </mat-form-field>
  </div>


  <!--Поле для ввода телефона-->
  <div class="row" *ngIf="currentState === smsSendState">
    <mat-form-field style="width: 250px;" class="accept-phone-code">
      <mat-label>СМС код</mat-label>
      <input
        #inputSmsCode
        mask="0000"
        matInput maxlength="4"
        required
        [(ngModel)]="smsCode"
      />
    </mat-form-field>
  </div>

  <!--кнопка запроса смс кода  и отправки смс кода-->
  <div class="row" *ngIf="!isAuth">
    <button [disabled]="phoneForSms.length !== 10"
            (click)="sendSms()"
            mat-button style="width: 250px;"
            class="button-send-phone">{{getTitleButtonSms()}}
    </button>
  </div>

</mat-dialog-content>


